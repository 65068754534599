<template>
<seccion-datos :titulo="bancodatos" @cambios="guardar()">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label >{{ $t('general.nombre') }}</label>
                    <input type="text" class="form-control nombre_completo dato" v-model="nombre_banco" placeholder="">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label >{{ $t('general.localidad') }}</label>
                    <input type="text" class="form-control nombre_completo dato" v-model="localidad_banco" id="nombre_completo" placeholder="">
                </div>   
            </div>
        </div>
        <div class="row">
            <div class="col-md-8">
                <div class="form-group">
                    <label >{{ $t('general.empresa') }}&nbsp;</label>
                    <select class="custom-select form-control-sm" v-model="Seleccionado">
                        <option v-for="empresa in empresas" :key="empresa.nombre_empresa" :value="empresa.id_empresa">{{empresa.nombre_empresa}}</option>
                    </select>
                    </div>
                </div>
            </div>
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['id','banco'],
    data() {
        return {
            id_banco: null,
            nombre_banco: null,
            localidad_banco: null,
            cuenta_banco: null,
            id_empresa: null,
            guardando: false,
            empresas: [],
            Seleccionado: '',
            bancodatos:"Datos",
        }
    },
    methods: {
        cargardatos() {
            this.$store.dispatch('cambiarCargando', true);
            this.guardando = false;
            this.nombre_banco = this.banco.nombre_banco;
            this.localidad_banco = this.banco.localidad_banco;
            this.id_empresa = this.banco.id_empresa;
            this.id_banco = this.banco.id_banco;
            this.Seleccionado = this.banco.id_empresa;
            this.$store.dispatch('cambiarCargando', false);
            this.bancodatos = this.$t("general.datos");

        },
        async listadoEmpresas() {
            /* get  modulos/pwgsapi/index.php/empresas */
            this.$store.dispatch('cambiarCargando', true);
            const api = new PwgsApi;
            const datos = await api.get('empresas/simple');
            this.empresas = datos.datos;
            this.$store.dispatch('cambiarCargando', false);
        },
        async guardar() {
            /* put  modulos/pwgsapi/index.php/bancos/:id */
            const api = new PwgsApi;
            const datos = { nombre_banco: this.nombre_banco, localidad_banco: this.localidad_banco, id_empresa: this.Seleccionado };
            if (this.id > 0)
            {                
                try {
                this.$store.dispatch('cambiarGuardando', true);
                await api.put('bancos/' + this.id_banco, datos);
                this.$store.dispatch('cambiarGuardando', false);
                this.$parent.$parent.obtenerBanco(this.id_banco);
                this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                }
                 catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
            else {
                /* post  modulos/pwgsapi/index.php/bancos */
                let res;
                try {
                    this.$toast.add({ severity: 'success', summary: 'Guardado', detail: 'Datos guardados correctamente', life: 2000 });
                    res = await api.post('bancos/', datos);
                    this.$router.push('/bancos/' + res.id);
                }
                catch (error) {
                    this.$toast.add({ severity: 'error', summary: 'PiError', detail: error, life: 5000 });
                }
            }
        },
    },
    mounted() { 
        this.cargardatos();
        this.listadoEmpresas();
    },
    watch: {
        banco() {
            this.cargardatos();
            this.listadoEmpresas();
        }
    },
}
</script> 