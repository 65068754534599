<template #default>
    <div class="row">
        <div class="col-md-5">
            <banco-datos-datos 
            :banco="banco"
            :id="id"/>
        </div>
        <div v-if="(id > 0)" class="col-md-6">
            <banco-datos-cuentas 
            :banco="banco"
            :id="id"/>
            <!--<seccion-datos-pro >
                <template #simple>
                    <p>hola</p>
                </template>
                <template #detallado>
                    <p>adios</p>
                </template>
                <template #form>

                </template>
            </seccion-datos-pro>-->
        </div>
    </div>
</template>

<script>
import BancoDatosDatos from './BancoDatosDatos.vue'
import BancoDatosCuentas from './BancoDatosCuentas.vue'
//import SeccionDatosPro from '../../../secciondatosavanzada/SeccionDatosPro.vue'
export default {
    props: ['id', 'banco'],
    components: {
        'banco-datos-datos': BancoDatosDatos,
        'banco-datos-cuentas': BancoDatosCuentas,
       // 'seccion-datos-pro': SeccionDatosPro,
    },
    data() {
        return {}
    }
}
</script>
