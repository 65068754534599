<template>
<seccion-datos :titulo="cuentastitulo" tipo="warning" :="false" :botonGuardar="false">
            <div class="row">
                <div class="col-md-10">
                    <div class="input-group mb-3">
                      <input type="text" v-model="iban_cuenta" class="form-control form-control-sm" style="width:20px">
                      <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                      <input type="text" v-model="entidad_cuenta" class="form-control form-control-sm" >
                      <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                      <input type="text" v-model="oficina_cuenta" class="form-control form-control-sm" >
                      <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                      <input type="text" v-model="control_cuenta" class="form-control form-control-sm" >
                      <span class="input-group-text form-control-sm" id="basic-addon1">-</span>
                      <input type="text" v-model="numero_cuenta" class="form-control form-control-sm" >
                    </div>
                </div>
                <div class="col-md-1">
                    <button class="btn btn-primary" @click="añadir(this.banco.id)">{{ $t('general.anadir') }}</button>
                </div>
            </div>
            <div class="row">
                <div class="col-md-10">
                    <table class="table table-sm table-striped">
                        <tbody v-if="cuentas.length >1"> 
                            <tr v-for="cuenta of cuentas" :key="cuenta.id_cuenta"> 
                                <td class="col-md-10">
                                    {{cuenta.iban_cuenta}}-{{cuenta.entidad_cuenta}}-{{cuenta.oficina_cuenta}}-{{cuenta.control_cuenta}}-{{cuenta.numero_cuenta}}
                               </td><td class="col-md-2">
                                    <button type="button" class="btn btn-block btn-outline-danger btn-xs" @click="eliminarCuenta(cuenta.id,cuenta.id_cuenta)">{{ $t('general.eliminar') }}</button>
                               </td>                                                          
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td v-if="cuentas" class="col-md-10">{{cuentas.iban_cuenta}}-{{cuentas.entidad_cuenta}}-{{cuentas.oficina_cuenta}}-{{cuentas.control_cuenta}}-{{cuentas.numero_cuenta}}              
                                </td>
                                <td v-if="cuentas" td class="col-md-2"><button type="button" class="btn btn-block btn-outline-danger btn-xs" @click="eliminarCuenta(cuentas.id,cuentas.id_cuenta)">{{ $t('general.eliminar') }}</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
    </seccion-datos>
</template>
<script>
import { PwgsApi } from '../../../../services/PwgsApi';
export default {
    props: ['banco','id'],
    data() {
        return {
            cuentas: [],
            iban_cuenta: '',
            entidad_cuenta: '',
            oficina_cuenta: '',
            control_cuenta: '',
            numero_cuenta: '',
            cuentastitulo: "Cuentas"
        }
    },
    methods: {
        async añadir(id) {
            /* post  modulos/pwgsapi/index.php/Bancos/:id/cuentas */
            const api = new PwgsApi;
            const subidadatos = { iban_cuenta: this.iban_cuenta, entidad_cuenta: this.entidad_cuenta, oficina_cuenta: this.oficina_cuenta, control_cuenta: this.control_cuenta, numero_cuenta: this.numero_cuenta };
            await api.post('bancos/' + id + '/cuentas', subidadatos);
            this.listadoCuentas(this.banco.id);
            this.iban_cuenta = '';
            this.entidad_cuenta = '';
            this.oficina_cuenta = '';
            this.control_cuenta = '';
            this.numero_cuenta = '';
            this.$parent.$parent.obtenerCuentas(this.id);            
        },
        async eliminarCuenta(idbanco, idcuenta) {
            /* delete  modulos/pwgsapi/index.php/Bancos/:id/cuentas/:id_cuenta */
            const api = new PwgsApi;
            await api.delete('bancos/' + idbanco + '/cuentas/' + idcuenta);
            this.listadoCuentas(this.banco.id);
            this.$parent.$parent.obtenerCuentas(this.id);
        },
        async listadoCuentas(id) {
            /* get  modulos/pwgsapi/index.php/bancos/:id/cuentas */
            const api = new PwgsApi;
            const datos = await api.get('bancos/' + id + '/cuentas');
            this.cuentas = datos.datos;
            this.cuentastitulo = this.$t("general.cuentas");
        },
      },
    watch: {
        banco() {
            this.listadoCuentas(this.banco.id);
            this.$parent.$parent.obtenerCuentas(this.id);
        }
    }
}
</script>