<template>
    <div class="container-fluid">
        <section class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                <div v-if="(id > 0)" class="col-sm-6">
                    <h1>{{ $t('general.banco') }}</h1>
                </div>
                <div v-else class="col-sm-6">
                    <h1>{{ $t('general.nuevobanco') }}</h1>
                </div>
                </div>
            </div>
        </section>
        <section class="content">
            <div class="row">
                <!-- Ocultar esta columna en la creación -->
                <div v-if="(id > 0)" class="col-md-2">
                    <!-- Datos banco -->
                    <div class="card bg-gradient-primary">
                        <div class="card-body box-profile">
                            <h3 class="profile-username text-center">{{banco.nombre_banco}}</h3>
                            <p class="text-muted text-center text-white">{{banco.localidad_banco}}</p>
                        </div>
                    </div>
                    <div class="card card-primary card-outline">
                        <div class="card-body box-profile">
                            <ul class="list-group list-group-unbordered mb-3">
                            <li class="list-group-item">
                              <b>{{ $t('general.cuentas') }}</b> <a class="float-right">{{cuentas}}</a>
                            </li>
                            </ul>
                        </div>
                    </div>
                    <!--<div class="callout callout-info">
                  <h5>Última modificación</h5>
                    <p>15/12/2021 15:35h por soporte2</p>
                    </div>-->
                </div>
                <div class="col-md-10">
                    <div v-if="(id > 0)" class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#datos_banco" data-toggle="tab">{{ $t('general.datos') }}</a></li>  
                            </ul>
                        </div>
                    </div>
                    <div class="tab-content">
                        <div class="active tab-pane" id="datos_banco">
                            <banco-datos
                            :banco="banco"
                            :id="id" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import BancoDatos from './datos/BancoDatos.vue'
export default {
    components: {
        'banco-datos': BancoDatos,
    },
    props: ['id'],
    data() {
        return {
            banco: [],
            cuentas: '',
        }
    },
    methods: {
        async obtenerBanco(id) {
           /* get  modulos/pwgsapi/index.php/bancos/:id */
            const api = new PwgsApi;
            const datos = await api.get('bancos/' + id);
            this.banco = datos.datos;
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;

            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == "/bancos/"+ventanaid) {
                    this.$store.getters.ventanas[i].name = this.banco.nombre_banco;
                    this.$store.getters.ventanas[i].alt = "Banco: " + this.banco.nombre_banco + " || " + this.banco.localidad_banco;
                }
            }
            /**/
        },
        async obtenerCuentas(id) {
            const api = new PwgsApi;
            const datos = await api.get('bancos/' + id + '/cuentas');
            this.cuentas = datos.length;
         },
        cambios() {
            this.obtenerBanco(this.id);
        },
        recargar() {
            this.obtenerBanco(this.id);
        },
    },
    mounted() {
        this.obtenerBanco(this.id);
        this.obtenerCuentas(this.id);
    },
    watch: {
        id(){ 
            this.obtenerBanco(this.id);
        },
        $route() {
            /**/
            let ventana = '';
            let ventanaid = this.$props.id.toString();
            let ventanas = this.$store.getters.ventanas;

            for (let i = 0; i < ventanas.length; i++) {
                ventana = this.$store.getters.ventanas[i].path;
                if (ventana == "/bancos/" + ventanaid) {
                    this.$store.getters.ventanas[i].name = this.banco.nombre_banco;
                    this.$store.getters.ventanas[i].alt = "Banco: " + this.banco.nombre_banco + " || " + this.banco.localidad_banco;
                }
            }
            /**/
        }
        
    }
}
</script>
